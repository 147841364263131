import React from 'react'
import GraphQLErrorList from '../components/graphql-error-list'
import ContentContainer from '../containers/content'

const Errors = ({ errors }) => (
  <ContentContainer>
    <GraphQLErrorList errors={errors} />
  </ContentContainer>
)

export default Errors
